// Colors
$orange: #fc9b18;
$green: #00c072;
$red: #ff0000;
$success-green: #00c092;
$primary: lightgrey;
$primary-text: #ffbe19;
$white: #fff;
$text-grey: #8f8f8f;
$black: #2d2d2d;

$outline-grey: lightgrey;
$loading-grey: darkgrey;
$textbox-grey: #fafafa;

// Borders
$brand-border: 1px solid $outline-grey;
$gold-border: 0.5rem solid $primary;
$gold-rim-border: 1px solid $primary;
$green-border: 1px solid $green;

$selectable-border: 2px solid $outline-grey;
$selectable-gold-border: 2px solid $primary;

// Classes
.yellow-button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  background-color: $primary;

  border: none;
  padding: 0.5rem 1rem;
  border-radius: 15px;
}

.white-button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  background-color: $white;
  border: $brand-border;

  padding: 0.5rem 1rem;
  border-radius: 15px;
}

.bold {
  font-size: 1.2rem;
  font-weight: bold;
}

// sharelink头部
$shareLinkHeaderBigHeight: 96px;
$shareLinkHeaderSmallHeight: 102px;

// 移动端头部高度
$mobileHeaderHeight: 80px;
// pc端头部高度
$pcHeaderHeight: 118px;
