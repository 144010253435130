@font-face {
  font-family: "HellixSemiBold";
  src: url("../../font/Hellix/Hellix-SemiBold.ttf");
}

.hintModalComponent {
  position: fixed;
  z-index: 99999;
  width: 327px;
  height: 66px;
  left: 50%;
  top: 50%;
  .inner {
    width: 100%;
    height: 100%;
    margin-left: -50%;
    margin-top: -50%;
    background-color: white;
    border: 1px solid #ebebeb;
    border-radius: 14px;
    display: flex;
    align-items: center;
    .rightIcon{
      width: 32px;
      height: 32px;
      margin-right: 18px;
      margin-left: 16px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .mess{
      font-size: 16px;
      color: #1a1d1f;
      font-family: HellixSemiBold;
    }
  }
}
