@import "styles/variables";

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  background: $white;
  font-feature-settings: normal;
  font-variant: normal;
}

////@font-face {
////  font-family: "Montserrat-Bold";
////  src: url("//db.onlinewebfonts.com/t/d3085f686df272f9e1a267cc69b2d24f.eot");
////  src: url("//db.onlinewebfonts.com/t/d3085f686df272f9e1a267cc69b2d24f.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/d3085f686df272f9e1a267cc69b2d24f.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/d3085f686df272f9e1a267cc69b2d24f.woff") format("woff"), url("//db.onlinewebfonts.com/t/d3085f686df272f9e1a267cc69b2d24f.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/d3085f686df272f9e1a267cc69b2d24f.svg#Montserrat") format("svg");
////}
//
//@font-face {
//  font-family: "Montserrat";
//  src: url("./assets/fonts/Montserrat-Regular.ttf");
//}
//@font-face {
//  font-family: "Montserrat Medium";
//  src: url("./assets/fonts/Montserrat-Medium.ttf");
//}
//
//@font-face {
//  font-family: "Montserrat SemiBold";
//  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
//}
//
//@font-face {
//  font-family: "Montserrat Light";
//  src: url("./assets/fonts/Montserrat-Light.ttf");
//}
//
//@font-face {
//  font-family: "Varela Round";
//  src: url("./assets/fonts/VarelaRound-Regular.ttf");
//}
//
//@font-face {
//  font-family: "Point DEMO Semi Bold";
//  src: url("./assets/fonts/point-demo-semi-bold.otf");
//}
//@font-face {
//  font-family: "Point Soft DEMO Semi Bold";
//  src: url("./assets/fonts/point-soft-demo-semi-bold.otf");
//}
//@font-face {
//  font-family: "Segoe UI";
//  src: url("./assets/fonts/Segoe UI.ttf");
//}
//@font-face {
//  font-family: "Virtuous Slab";
//  src: url("./assets/fonts/Virtuous-Slab.otf");
//}

a {
  color: black;
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
}

.ant-select-selection-search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  margin: auto;
  max-width: 1320px;
}

.ant-form-horizontal .ant-form-item-control {
  min-width: auto;
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
