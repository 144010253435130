.modal-public.status-item{
    margin-top: 20px;

    .ant-radio-group{
      display: flex;
      flex-direction: column;
      .ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner{
        border-color: #00CAD1;
      }
      .ant-radio-wrapper-checked .ant-radio-checked .ant-radio-inner::after{
        background-color: #00CAD1;
      }
      .ant-radio-wrapper{
        span{
          font-family: HellixSemiBold;
          font-size: 16px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: -0.16px;
          text-align: left;
          color: #1a1d1f;
        }
      }
    }
  }
  .modal-public.select-campaigns-title{
    font-family: HellixBold;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.12px;
    text-align: left;
    color: #6c737e;
    //margin-top: 32px;
  }
  .modal-public.campaigns-name-title{
    margin-top:20px;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-family: HellixBold;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.1px;
    text-align: left;
    color: #6c737e;
  }

  .modal-public.campaigns-name-text{
    margin-top:20px;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
    font-family: HellixSemiBold;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.1px;
    text-align: left;
    color: #6c737e;
  }

  .modal-public.campaigns-status-message{
    font-family: HellixMedium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    margin-top: 17px;
  }
  .modal-public.campaigns-item{
    max-height: 124px;
    &::-webkit-scrollbar {
      display: none;
    }
    overflow-y: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper{
      margin-left: 0px;
      span{
        font-family: HellixBold;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: -0.14px;
        text-align: left;
        color: #212529;
      }
    }
  }
  .modal-public.buttons-area{
    margin-top: 40px;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 40px;
    .button-cancel{
      width: 111px;
      height: 52px;
      border-radius: 8px;
      border: solid 1px #121212;
      font-family: HellixSemiBold;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: center;
      color: #212529;
      align-items: center;
      justify-content: space-around;
      display: flex;
    }
    .button-copy{
      margin-left: 13px;
      width: 165px;
      height: 52px;
      border-radius: 8px;
      background-color: #00CAD1;
      font-family: HellixSemiBold;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: center;
      color: #212529;
      align-items: center;
      justify-content: space-around;
      display: flex;
    }
  }